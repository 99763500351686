import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSubscribe } from "hooks/Forms/useSubscribe";
import { useLocation } from "@reach/router";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";

const Form = () => {
  const [formStatus, setForStatus] = useState(false);
  const { mutateAsync } = useSubscribe();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setForStatus(true);
    });
  };

  return (
    <React.Fragment>
      <div className="w-72 border border-newBorder py-10 m-auto mt-10 bg-lightestBlue text-center">
        <h2 className="text-2xl font-bold text-center ">
          <span className="text-orange">STAY </span>INFORMED
        </h2>
        <p className="text-sm text-center py-6">
          Subscribe to receive all the latest updates from Tomedes.
        </p>{" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          {errors.email && (
            <span className="text-red text-sm">{errorMessageEmail}</span>
          )}
          <input
            type="email"
            name="email"
            autoComplete="off"
            {...register("email", {
              required: true,
              pattern: patternEmail,
            })}
            placeholder="Enter your business email "
            className="bg-white m-auto block text-sm mb-6 pl-2 w-11/12 lg:w-9/12 sm:w-10/12 border border-newBorder py-2 font-opensans"
          />
          {formStatus ? (
            <span className="text-[#96D149] text-sm">
              Thank you! Please check your <br />
              inbox for a confirmation email
            </span>
          ) : (
            <></>
          )}
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button
            type="submit"
            className="bg-darkestBlue hover:bg-hoverBtn py-3 text-borderColor font-bold px-4 text-sm block m-auto uppercase"
          >
            Keep Me Updated
          </button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default Form;
